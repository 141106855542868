<template>
    <el-container class="a-page">
        <el-main class="main">
            <h1 class="title">{{title}}</h1>
            <div class="article" v-html="article"></div>
        </el-main>
    </el-container>
</template>


<script>

    export default {
      data() {
        return {
          banner: null,
        };
      },

      computed: {
        title() {
          return !this.banner ? '' : this.$i18n.locale === 'zh-CN' ? this.banner.zhTitle : this.banner.enTitle;
        },

        article() {
          return !this.banner ? '' : this.$i18n.locale === 'zh-CN' ? this.banner.zhContent : this.banner.enContent;
        }
      },

      beforeRouteEnter(to, from, next) {
          const { articleId, title, lang } = to.query;
          window.$ajax.get('/carousel/article', {
              params: {
                  id: articleId,
                  title: title,
                  lang: lang
              }
          }).then(response => {
              next(vm => vm.setBanner(response.data));
          }).catch(() => {
              // 发生异常，后退一步
              next(vm => vm.$router.go(-1));
          });

        // let bannerArticle = null;
        // if (articleId) {
        //   bannerArticle = sessionStorage.getItem(`BannerArticle-Id-${articleId}`);
        //   bannerArticle = JSON.parse(bannerArticle);
        // }
        // if (!bannerArticle && title) {
        //   bannerArticle = sessionStorage.getItem(`BannerArticle-Title-${title}`);
        //   bannerArticle = JSON.parse(bannerArticle);
        // }
        //
        // if (!bannerArticle) {
        //   window.$ajax.get('/carousel/article', {
        //     params: {
        //       id: articleId,
        //       title: title,
        //       lang: lang
        //     }
        //   }).then(response => {
        //     // 从后台读到了，设置渲染数据，并存入sessionStorage
        //     bannerArticle = response.data;
        //     const json = JSON.stringify(bannerArticle);
        //     sessionStorage.setItem(`BannerArticle-Id-${articleId}`, json);
        //     sessionStorage.setItem(`BannerArticle-Title-${bannerArticle.enTitle}`, json);
        //     sessionStorage.setItem(`BannerArticle-Title-${bannerArticle.zhTitle}`, json);
        //     next(vm => vm.setBanner(bannerArticle));
        //   }).catch(() => {
        //     // 发生异常，后退一步
        //     next(vm => vm.$router.go(-1));
        //   });
        // } else {
        //   // 从sessionStorage中读到了，直接使用
        //   next(vm => vm.setBanner(bannerArticle));
        // }

      },

      created() {
        window.page = this;
      },

      methods: {
        setBanner(banner) {
          this.banner = banner;
        },
      }

    }

</script>


<style lang="scss">
    .a-page {
        width: 100%;
        min-height: 300px;
        background-color: var(--white);

        .main {
            width: 100%;
            max-width: 1128px;
            margin: 0 auto;

            .title {
                margin: 40px 0;
                font-size: 30px;
                text-align: center;
                @media screen and (max-width: 768px) {
                    font-size: 24px;
                    margin: 20px 0;
                }
            }

            .article {
                margin-top: 40px;
                text-align: justify;
                padding: 0px 10px 10px 10px;

                @media screen and (max-width: 768px) {
                    margin-top: 0px;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
</style>
